import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import '../styles/index.scss';

Swiper.use([Navigation, Pagination]);

const collapsibles = document.querySelectorAll('.collapsible-item');

const resetOtherCollapsibles = (skip) => [...collapsibles].forEach((collapsible, index) => {
    if (skip == index) return;
    collapsible.classList.remove('active');
    const content = collapsible.querySelector('.collapsible-content');
    content.style.maxHeight = null;
});

[...collapsibles].forEach((collapsible, index) => {
  collapsible.addEventListener('click', () => {
    const content = collapsible.querySelector('.collapsible-content');

    if (collapsible.classList.contains('active')) {
      collapsible.classList.remove('active');
      content.style.maxHeight = null;
    } else {
      collapsible.classList.add('active');
      content.style.maxHeight = content.scrollHeight + "px";
    }

    resetOtherCollapsibles(index);
  });

  if (collapsible.classList.contains('active')) {
    setTimeout(() => {
      let content = collapsible.querySelector('.collapsible-content');
      content.style.maxHeight = content.scrollHeight + "px";
    }, 100);
  }
});

// Scroll to a certain element
const anchors = document.querySelectorAll('a[href^="#"]');
[...anchors].forEach((anchor) => {
  anchor.addEventListener('click', () => {
    anchor.scrollIntoView({
      behavior: 'smooth'
    });
  });
});

(function() {
  var elements;
  var windowHeight;

  function init() {
    elements = document.querySelectorAll('.is-hidden');
    windowHeight = window.innerHeight;
  }

  function checkPosition() {
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      var positionFromTop = elements[i].getBoundingClientRect().top;

      if (positionFromTop - windowHeight <= 0 && !element.classList.contains('is-active')) {
        element.classList.add('is-active');
        console.log('added');
      }
    }
  }

  window.addEventListener('scroll', checkPosition);
  window.addEventListener('resize', init);

  init();
  checkPosition();
})();

const swiper = new Swiper('.swiper-container', {
  autoHeight: true,
  slidesPerView: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  centeredSlides: true,
  autoplay: {
    delay: 2500,
  },
});

setTimeout(() => {
  swiper.update();
}, 100);

// tabs
const links = [...document.querySelectorAll(".tabs-navigation button")];
const blocks = [...document.querySelectorAll(".tabs-content > div")];

const hideAllContent = () => {
  blocks.forEach((div) => {
    div.classList.remove("show");
  });
};

links.forEach((link) => {
  link.addEventListener("click", () => {
    hideAllContent();

    const target = link.getAttribute('data-target');
    const content = document.querySelector(target);

    content.classList.add("show");
    return false;
  });
});
